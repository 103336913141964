import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// Components
import Modal from '../../../../Modal';
import NavBar from '../../../../NavBar';
import Scene from '../../../../Scene';
import Heading from '../../../../Heading/MobileView';
import Button from '../../../../Button';

// Utils
import { getStopListSauceReplacements } from '../../../../../utils/cart';

// Icons
import GiftIcon from '../../../../../icons/gift.svg';

// Styles
import styles from './SaucesList.styl';

const cx = classNames.bind(styles);

export default function SaucesList(props) {
  const { isOpen, className, sauces, disabled, stopList, onHide, addFreeSauce } = props;

  const intl = useIntl();

  const onItemClick = sauce => {
    onHide();
    addFreeSauce(sauce.variations[0].id);
  };

  return (
    <Modal className={cx('SaucesList', className)} direction="left" isOpen={isOpen}>
      <Scene
        renderNavbar={() => (
          <NavBar
            view="secondary"
            onBack={onHide}
            title={intl.formatMessage({ id: 'freeSauces.modal.title' })}
          />
        )}
      >
        <div className={cx('SaucesList__list')}>
          {sauces.map(sauce => {
            const { variations, name, id } = sauce;
            const { image_cart, size } = variations[0];

            if (getStopListSauceReplacements(stopList, variations[0])) {
              return null;
            }

            return (
              <button
                className={cx('SaucesList__item')}
                key={id}
                onClick={() => (disabled ? undefined : onItemClick(sauce))}
                type="button"
              >
                <figure className={cx('SaucesList__image')}>
                  <img src={image_cart} alt="" />
                </figure>
                <div className={cx('SaucesList__info')}>
                  <Heading className={cx('SaucesList__name')} level="5" tagName="div">
                    {name}
                  </Heading>
                  <div className={cx('SaucesList__description')}>
                    {size.value}&nbsp;
                    {size.unit}
                  </div>
                </div>
                <Button className={cx('SaucesList__button')} small>
                  <GiftIcon />
                </Button>
              </button>
            );
          })}
        </div>
      </Scene>
    </Modal>
  );
}

SaucesList.defaultProps = {
  className: '',
  isOpen: false,
  disabled: false,
  stopList: [],
};

SaucesList.propTypes = {
  className: PropTypes.string,
  sauces: PropTypes.array.isRequired,
  stopList: PropTypes.array,
  addFreeSauce: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};
